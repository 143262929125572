<template>
  <div>
    <section id="heading">
      <div class="row align-items-center no-gutters pl-3 pr-3">
        <div class="col">
          <h1 class="main-heading">
            <div>{{ stringAsset("partner/main-heading", lang) }}</div>
            <span class="text-primary">{{
              stringAsset("partner/sub-heading", lang)
            }}</span>
          </h1>
          <!-- <p class="text-muted"> -->
          <!--   Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do -->
          <!--   eiusmod tempor incididunt ut labore et dolore magna aliqua. -->
          <!-- </p> -->
        </div>
        <div class="col col-md-auto text-center">
          <img
            src="@/assets/Partner_heading.png"
            class="img-partner-heading"
            alt="matchday partner heading"
          />
        </div>
      </div>
    </section>
    <section id="features">
      <div class="features-row align-items-center">
        <div class="col text-center">
          <img src="@/assets/Partner_features.svg" alt="" class="img-fluid" />
        </div>
        <div class="col-md">
          <div class="sm-container">
            <h2 class="mb-3 sub-heading">
              {{ stringAsset("partner/feature-heading", lang) }}
              <span
                class="text-primary baloo"
                style="font-weight: 700; font-size: 3.3rem"
                >{{ stringAsset("common/app_name", "en") }}</span
              >
            </h2>
            <div>
              <ul class="list-group">
                <li
                  class="list-group-item"
                  v-for="feature in features"
                  :key="feature.heading"
                >
                  <div class="features-item">
                    <div class="feature-icon mr-3 shadow">
                      <img :src="feature.icon" />
                    </div>
                    <div>
                      <h4>
                        {{ feature.heading }}
                      </h4>
                      <p class="text-muted">
                        {{ feature.content }}
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="clip-embed">
      <div class="yt-player-wrapper p-3">
        <div class="yt-player">
          <iframe
            :src="`https://www.youtube.com/embed/${youtubeVideoId}`"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </section>
    <section id="review">
      <div>
        <h2 class="text-center sub-heading">
          {{ stringAsset("partner/review-heading-1", lang) }}
          <span class="text-primary">{{
            stringAsset("partner/review-heading-1-highlight", lang)
          }}</span>
          {{ stringAsset("partner/review-heading-1-postfix", lang) }}
          <div class="text-primary">
            {{ stringAsset("partner/review-heading-2", lang) }}
          </div>
        </h2>
      </div>
      <div class="row no-gutters align-items-center">
        <div class="col-auto">
          <button class="btn-chevron text-dark" @click="scrollTo('review-row')">
            <i class="fas fa-chevron-left"></i>
          </button>
        </div>
        <div
          class="col text-center mt-5 pt-5 pb-5 mt-5 review-row-wrapper"
          id="review-row"
        >
          <div
            class="row no-gutters provider-review-row"
            id="review-row"
            :class="reviews.length > 4 ? 'scroll' : ''"
          >
            <div
              class="col-auto shadow p-1 ml-3 mr-3"
              v-for="review in reviews"
              :key="review.id"
            >
              <div class="review-card">
                <div>
                  <div>
                    <img
                      :src="review.avatar || defaultAvatar"
                      @error="missingLogo"
                      class="review-profile"
                      alt=""
                    />
                  </div>
                  <div class="review-text-content row no-gutters">
                    <div class="col-auto">
                      <img src="@/assets/left-quote.svg" class="quote" alt="" />
                    </div>
                    <div class="col">
                      <p class="pl-1 pr-1">
                        {{ review.review }}
                      </p>
                    </div>
                    <div class="col-auto right-quote-wrapper">
                      <img
                        src="@/assets/left-quote.svg"
                        class="quote rotate-quote"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div class="line line-gray mt-3 mb-3"></div>
                  <h4>{{ review.name }}</h4>
                  <span class="text-muted"> </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-auto">
          <button
            class="btn-chevron text-dark"
            @click="scrollTo('review-row', true)"
          >
            <i class="fas fa-chevron-right"></i>
          </button>
        </div>
      </div>
    </section>
    <section id="trial">
      <div class="p-3">
        <div
          class="row no-gutters trial-form rounded"
          :style="`background-image: url(${bg_trial})`"
        >
          <div class="col-md text-center text-light">
            <h2>{{ stringAsset("partner/trial-heading", lang) }}</h2>
            <div class="pt-3 pb-5">
              <img
                class="sport-icon"
                alt=""
                v-for="(icon, index) in [...new Set(sport_icons)]"
                :key="`spic-${index}`"
                :src="icon"
              />
            </div>
            <button class="btn-img" @click="focusForm">
              <img
                src="@/assets/Partner_trial.png"
                class="img-fluid sm-hide"
                alt=""
              />
            </button>
          </div>
          <div class="col text-center text-light">
            <div class="bg-white rounded p-3 text-dark sm-container">
              <h3 class="mb-5">
                {{ stringAsset("partner/trial-form-heading-1", lang) }}
                <div>
                  {{ stringAsset("partner/trial-form-heading-2", lang) }}
                </div>
              </h3>
              <form @submit="trialSubmit">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.name"
                    id="form-name"
                    :placeholder="stringAsset('common/name-placeholder', lang)"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.provider_name"
                    :placeholder="
                      stringAsset('common/provider-name-placeholder', lang)
                    "
                  />
                </div>
                <div class="form-group">
                  <input
                    type="email"
                    class="form-control"
                    v-model="form.email"
                    :placeholder="stringAsset('common/email-placeholder', lang)"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.phone"
                    :placeholder="stringAsset('common/phone-placeholder', lang)"
                  />
                </div>
                <button type="submit" class="btn btn-primary btn-block">
                  {{ stringAsset("common/submit") }}
                </button>
              </form>
            </div>
            <div class="pt-3 pb-3 row no-gutters align-items-center">
              <div class="col line"></div>
              <div class="col-auto pl-3 pr-3">
                {{ stringAsset("common/conjunction-or", lang) }}
              </div>
              <div class="col line"></div>
            </div>
            <div class="mb-3">
              <div class="phill">
                <span class="text-primary">
                  <i class="fas fa-phone-alt"></i>
                </span>
                <span class="text-dark"> 02-119-8414</span>
              </div>
            </div>
            <div>
              <div class="phill">
                <span class="text-primary pr-1">
                  <i class="fas fa-envelope"></i>
                </span>
                <span class="text-dark">admin@matchday.co.th</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="our-partners">
      <h2 class="sub-heading text-center">
        {{ stringAsset("partner/ourpartner-heading", lang) }}
      </h2>
      <div class="row no-gutters align-items-center justify-content-center">
        <div class="col-auto">
          <button
            class="btn-chevron text-dark"
            @click="scrollTo('sport-type-wrapper')"
          >
            <i class="fas fa-chevron-left"></i>
          </button>
        </div>
        <div class="sport-type-wrapper col" id="sport-type-wrapper">
          <div class="btn-group btn-group-toggle" data-toggle="buttons">
            <span id="partners-start"></span>
            <label
              class="btn btn-light mr-1 ml-1"
              v-for="sport in sports"
              :key="sport.id + 'sport'"
            >
              <input
                type="radio"
                name="selected-sport"
                v-model="selectedSport"
                :value="sport.id"
              />
              {{ sport.name }}
            </label>
            <span id="partners-end"></span>
          </div>
        </div>
        <div class="col-auto">
          <button
            @click="scrollTo('sport-type-wrapper', true)"
            class="btn-chevron text-dark"
          >
            <i class="fas fa-chevron-right"></i>
          </button>
        </div>
      </div>
      <div class="sport-provider-wrapper">
        <div class="sport-provider-list">
          <img
            :src="logo"
            v-for="logo, i in filterdProvider()"
            :key="i + 'spd'"
            alt=""
            class="sport-provider-logo"
          />
        </div>
      </div>
    </section>
    <div class="loader" ref="loader">
      <div class="loader-inner">
        <div class="spinner-grow text-primary" role="status"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getStringAsset, getStringAssetSet } from "../assets/strings";
import featureIcons1 from "../assets/icons/partner/Partner_feature_1.png";
import featureIcons2 from "../assets/icons/partner/Partner_feature_2.svg";
import featureIcons3 from "../assets/icons/partner/Partner_feature_3.png";
import bg_trial from "../assets/Partner_trial_bg.svg";
import fb_icon from "../assets/icons/partner/Football.png";
import ac_icon from "../assets/icons/partner/Ahcher.png";
import bs_icon from "../assets/icons/partner/Besball.png";
import bk_icon from "../assets/icons/partner/Basketball.png";
import bt_icon from "../assets/icons/partner/Batminton.png";
import pp_icon from "../assets/icons/partner/Pingpong.png";
import tn_icon from "../assets/icons/partner/Tennis.png";
import { mapActions, mapGetters } from "vuex";
import emailjs from "emailjs-com";
import additionalContent from "@/assets/strings/additionalContent";
import defaultAvatar from "@/assets/default-avatar.png";

export default {
  computed: {
    features() {
      const list = [];
      for (let i = 1; i <= 3; i++) {
        list.push({
          icon: this.featureIcons[i - 1],
          heading: getStringAsset(
            `partner/feature-item-heading-${i}`,
            this.lang
          ),
          content: getStringAsset(
            `partner/feature-item-content-${i}`,
            this.lang
          )
        });
      }
      return list;
    },
    sportStringAsset() {
      return getStringAssetSet("sports");
    },
    youtubeVideoId() {
      return additionalContent.tutorial_youtube_video_id;
    },
    ...mapGetters({
      providers: "sport/providers",
      sports: "sport/sports",
      reviews: "sport/provider_reviews"
    })
  },
  data() {
    return {
      lang: "th",
      featureIcons: [featureIcons1, featureIcons2, featureIcons3],
      bg_trial,
      sport_icons: [
        fb_icon,
        bs_icon,
        bk_icon,
        ac_icon,
        bt_icon,
        pp_icon,
        tn_icon
      ],
      selectedSport: 1,
      form: {
        name: "",
        email: "",
        provider_name: "",
        phone: ""
      },
      defaultAvatar
    };
  },
  mounted(){
      if(this.$route.query.trial === '1'){
        const el = document.getElementById('trial');
      el.scrollIntoView({behavior: "smooth"});
      }

  },  
  methods: {
    stringAsset: getStringAsset,
    async trialSubmit(event) {
      if(!this.form.provider_name && !this.form.email && !this.form.name && !this.form.phone) return alert("กรุณากรอกข้อมูลให้ครบถ้วน");
      event.preventDefault(true);
      this.$refs.loader.classList.add("active");
      try {
        await emailjs.send(
          "service_landingpage",
          "template_partner",
          {
            provider_name: this.form.provider_name,
            email: this.form.email,
            name: this.form.name,
            phone: this.form.phone
          },
          "user_OdEwyPhb2XD2vBr4kZmpj"
        );
        this.$refs.loader.classList.remove("active");
        alert("ส่งข้อมูลเรียบร้อย");
      } catch (error) {
        console.log({ error });
      }
    },
    missingLogo(event) {
      event.target.src = defaultAvatar;
    },
    filterdProvider() {
      const target = this.providers.filter(provider =>
        provider.provider_sports.find(
          sport => sport.sport_id == this.selectedSport
        )
      ).map(({ logo }) => logo)
      return [...new Set(target)]
    },
    scrollTo(targetId, right) {
      const target = document.getElementById(targetId);
      const scrollOffset = right ? 500 : -500;
      target.scrollTo({
        top: 0,
        left: target.scrollTo({
          top: 0,
          left: target.scrollLeft + scrollOffset,
          behavior: "smooth"
        }),
        behavior: "smooth"
      });
    },
    focusForm() {
      const target = document.getElementById("form-name");
      document.body.scrollTo({
        top: target.offsetTop,
        left: 0,
        behavior: "smooth"
      });
      target.focus();
    },
    ...mapActions({
      getProviders: "sport/getSportProviders",
      getAllSports: "sport/getAllSports",
      getReviews: "sport/getProviderReviews"
    })
  },
  beforeMount() {
    this.getProviders();
    this.getAllSports();
    this.getReviews();
  }
};
</script>
<style scoped lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Baloo+Bhai+2:wght@400;500;600;700;800&display=swap");
.baloo {
  font-family: "Baloo Bhai 2", cursive;
  text-transform: lowercase;
}
.list-group li {
  border: none;
  padding: 1rem 0;
}
.main-heading {
  font-weight: 600;
  font-size: 3rem;
  @media (min-width: 768px) {
    font-size: 4rem;
  }
}
.sub-heading {
  font-weight: 600;
  font-size: 2rem;
  @media (min-width: 768px) {
    font-size: 3rem;
  }
  @media (max-width: 595px) {
    text-align: center;
  }
}
.yt-player-wrapper {
  max-width: 900px;
  margin: auto;
}
.yt-player {
  position: relative;
  padding: 25px 0 56.25% 0;
  height: 0;
}
.yt-player iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
}
.btn-img {
  background: none;
  border: none;
  outline: none;
}
.feature-icon {
  border-radius: 50%;
  width: 64px;
  height: 64px;
  img {
    width: 4.3rem;
    height: 4.3rem;
    padding: 1rem;
    object-fit: contain;
  }
}
.sm-container {
  max-width: 450px;
  margin: auto;
}
.form-control {
  border-radius: 2rem;
  padding: 1.5rem 2rem;
  background-color: var(--light);
}
section {
  padding: 3rem 0;
}
* {
  line-height: 1.5;
}
*:not(h1, h2, h3, h4, h5, h1 *, h2 *, h3 *) {
  font-weight: 400;
}
.line {
  height: 1px;
  background-color: var(--light);
}
.line-gray {
  background-color: #dddddd;
}
.phill {
  background-color: white;
  padding: 0.3rem 1rem;
  border-radius: 2rem;
  display: inline;
}
.img-partner-heading {
  max-width: calc(2.5 * 10rem);
}
.trial-form {
  background-size: cover;
  background-repeat: no-repeat;
  padding: 2rem 1rem;
  @media (min-width: 768px) {
    padding: 2rem 4rem;
  }
}
.sport-icon {
  width: 3rem;
  height: 3rem;
  object-fit: cover;
}
.btn-light.active {
  background-color: #d6032629 !important;
  color: #d60326 !important;
  border-color: transparent !important;
  box-shadow: none;
}
.btn-group .btn {
  border-radius: 2rem !important;
}
.btn-group {
  width: max-content;
}
.sport-type-wrapper {
  overflow: auto;
  display: inline;
  max-width: 900px;
  scroll-behavior: smooth;
}
.sport-type-wrapper::-webkit-scrollbar {
  display: none;
}
.btn-chevron {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 1rem;
  padding: 0.5rem;
}
.sport-provider-logo {
  width: 9rem;
  height: 9rem;
  object-fit: cover;
}
.sport-provider-wrapper {
  overflow: auto;
  max-width: 960px;
  margin: auto;
}
.sport-provider-list {
  display: flex;
}
.sport-provider-list::-webkit-scrollbar {
  display: none;
}
.sport-provider-list img {
  margin: 1rem;
}
.review-row-wrapper {
  overflow: auto;
}
.provider-review-row {
  justify-content: center !important;
  flex-wrap: nowrap;
  @media (min-width: 768px) {
    &:not(.scroll) {
      justify-content: center;
    }
  }
}
.review-card {
  width: calc(1.5 * 10rem);
  height: calc(1.6 * 10rem);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-overflow: ellipsis;
}
.review-profile {
  width: 6rem;
  height: 6rem;
  object-fit: cover;
  border-radius: 50%;
  transform: translateY(-50%);
}
.review-text-content {
  margin-top: -1.5rem;
  max-height: 150px;
  text-overflow: ellipsis;
}
.quote {
  width: 1rem;
  height: 1rem;
  object-fit: contain;
}
.rotate-quote {
  transform: rotate(180deg) translateY(100%);
}
.right-quote-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.features-item {
  display: flex;
  @media (max-width: 595px) {
    flex-direction: column;
    text-align: center;
    align-items: center;
    .feature-icon {
      margin: 2rem 0;
    }
  }
}
.features-row {
  display: flex;
  @media (max-width: 595px) {
    flex-direction: column-reverse;
  }
}
.sm-hide {
  @media (max-width: 595px) {
    display: none;
  }
}
.loader {
  position: fixed;
  inset: 0 0 0 0;
  width: auto;
  height: auto;
  background: rgba(255, 255, 255, 0.5);
  display: none;
}
.loader.active {
  display: block;
}
.loader-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
